import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Button, IconButton } from "components/common/ControlElems";
import { Note, RoundToggle } from "components/common/icons";

import { ContentContext } from "context/ContentContext";

import { FILTERS_INITIAL_STATE } from "context/helpers/contentContextHelper";

import { isEqual } from "context/helpers/contentContextHelper";

import styles from "./Preset.module.scss";
import withRouter from "components/helpers/withRouter";

Preset.propTypes = {
    preset: PropTypes.object.isRequired,
    currentPreset: PropTypes.string.isRequired,
    onPresetChange: PropTypes.func.isRequired,
    needToRedirect: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired
};

function Preset({
    preset,
    needToRedirect,
    history,
    currentPreset,
    setOpenedPreset,
    openedPreset,
    onPresetChange
}) {
    const {
        versions,
        bpm,
        genres,
        type,
        sortField,
        setPreset,
        searchQuery,
        presets,
        resetBannerRequest
    } = useContext(ContentContext);

    const [showGenres, toggleShowGenres] = useState(false);

    useEffect(() => {
        if (showGenres && openedPreset !== preset.name) toggleShowGenres(false);
    }, [openedPreset]);

    const isActivePreset =
        currentPreset === preset.name ||
        isEqual(preset, { genres, versions, bpm });

    const applyNewPreset = React.useCallback(
        ({ name, filter }, genre) => {
            const genres = genre ? [genre] : [];
            console.log(genres,"applyNewPreset---genres")
            onPresetChange({
                presetName: name,
                commonParams: {
                    ...FILTERS_INITIAL_STATE,
                    ...filter,
                    searchQuery,
                    genres,
                    type,
                    sortField
                },
                typeParams: { offset: 0 }
            });
        },
        [type, sortField, searchQuery]
    );

    const onToggleShowGenres = React.useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            e.cancelBubble = true;
            toggleShowGenres(!showGenres);
            setOpenedPreset(preset.name);
        },
        [showGenres]
    );

    const redirectToPreset = React.useCallback(preset => {
        setPreset(preset);
        setTimeout(() => {
            history.push(`/tracks/all`);
        }, 40);
    }, []);

    const buttonsStyles = {
        withoutBorder: true,
        withoutPadding: true,
        direction: "vertical"
    };

    const onClickPreset = React.useCallback(
        (preset, genre) => {
            resetBannerRequest();
            return needToRedirect
                ? redirectToPreset({ ...preset, genre })
                : applyNewPreset(preset, genre);
        },
        [needToRedirect, applyNewPreset]
    );

    const { name, filter } = preset;
    //console.log( name, filter);
    return (
        <div className={styles.container}>
            <div className={styles.mainButtonContainer}>
                <Button
                    key={name}
                    disabled={type === "video"}
                    direction="vertical"
                    className={classnames(
                        styles.presetButton,
                        isActivePreset && styles.activeButton
                    )}
                    active={isActivePreset}
                    onClick={() => onClickPreset(preset)}
                    label={name}
                    {...buttonsStyles}
                />
                { typeof filter != 'undefined' && typeof filter.genres != 'undefined' && filter.genres.length > 0 && (
                    <IconButton
                        className={classnames(
                            styles.toggleGenres,
                            showGenres && styles.showGenres
                        )}
                        disabled={type === "video"}
                        onClick={onToggleShowGenres}
                    >
                        <RoundToggle />
                    </IconButton>
                )}
                <NewSongsSign
                    type={type}
                    presets={presets}
                    currentPreset={name}
                />
            </div>

            <div
                className={classnames(styles.list, showGenres && styles.opened)}
            >
                { typeof filter != 'undefined' && typeof filter.genres != 'undefined' && filter.genres.length > 0 && [
                        <Button
                            className={classnames(
                                styles.genreButton,
                                isCurrentPresetAndGenre({
                                    preset,
                                    currentPreset,
                                    genres,
                                    genre: "All"
                                }) && styles.active
                            )}
                            key="all"
                            label="All"
                            onClick={() => onClickPreset(preset)}
                            {...buttonsStyles}
                        />,
                        ...preset.filter.genres.map(genre => {
                            //console.log(genre,"genre")
                            return (
                                <Button
                                    className={classnames(
                                        styles.genreButton,
                                        isCurrentPresetAndGenre({
                                            preset,
                                            currentPreset,
                                            genres,
                                            genre
                                        }) && styles.active
                                    )}
                                    key={genre}
                                    label={(genre !== "Calypso/Soca") ? genre : "Soca" }
                                    onClick={() => onClickPreset(preset, genre)}
                                    {...buttonsStyles}
                                />
                            );
                        })
                    ]}
            </div>
        </div>
    );
}

function isCurrentPresetAndGenre({ preset, currentPreset, genres, genre }) {
    let result = false;
    if (preset.name !== currentPreset) {
        result = false;
    } else if (genre === "All" && genres && genres.length !== 1) {
        result = true;
    } else if (genres && genres.length === 1 && genres.includes(genre)) {
        result = true;
    }

    return result;
}

function NewSongsSign({ type, presets, currentPreset }) {
    let result = null;
    if (type === "audio" && presets && presets.includes(currentPreset)) {
        result = <Note className={styles.note} />;
    }

    return result;
}

export default React.memo(withRouter(Preset));
