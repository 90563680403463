import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { SubscriptionContext } from "context/SubscriptionContext";
import { Button } from "components/common/ControlElems";
import { BaseDialog } from "../../common/Dialogs";
import TextArea from "../../common/form/TextArea";
import t from "helpers/getText";
import styles from "./ProfileDialogs.module.scss";
import { Scrollbar } from "../../common/Scrollbar";

Subscription.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default function Subscription({ onClose }) {
    const value = useContext(SubscriptionContext);
    const [explanationText, setExplanationText] = useState("");
    const {
        cancelSubscription,
        clearCancelSubscription,
        cancelSubscriptionProcessing,
        cancelSubscriptionResult
    } = value;

    const handleSubmit = () => {
        cancelSubscription({ message: explanationText });
    };

    useEffect(() => {
        if (cancelSubscriptionResult) {
            onClose();
        }

        return () => clearCancelSubscription();
    }, [cancelSubscriptionResult]);

    return (
        <BaseDialog
            withoutScrollbar
            onClose={onClose}
            height="tall"
            width="width"
        >
            <Scrollbar>
                <div className={styles.subscriptionContainer}>
                    <h2 className={styles.cancelHeader}>
                        {t("subscription.cancelSubscriptionHead")}
                    </h2>
                    <span className={styles.cancelText}>
                        {t("subscription.cancelSubscriptionText")}
                    </span>
                    <TextArea
                        lightMode={true}
                        label={"Leave a message"}
                        placeholder={"Message"}
                        max="512"
                        onChange={e => setExplanationText(e.target.value)}
                        value={explanationText}
                    />
                    <NavLink className={styles.termsLink} to="/agreement">
                        {t("subscription.cancelAgreementLink")}
                    </NavLink>
                    <Button
                        color="bright"
                        width="14.5rem"
                        onClick={handleSubmit}
                        disabled={cancelSubscriptionProcessing}
                        label={t("subscription.buttons.cancel")}
                    />
                </div>
            </Scrollbar>
        </BaseDialog>
    );
}
