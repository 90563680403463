const subscription = {
    subscription: {
        revive: {
            pay: [
                "Your subscription is currently inactive due to a cancellation request you might have submitted previously or a declined payment.",
                "In order to reactivate your account you are required to submit a payment of ",
                " Your next payment will be on ",
                " and the",
                " of each month thereafter until you submit a cancellation request. "
            ]
        },
        specific: {
            option1: [
                "Your last payment was on ",
                " which is your billing cycle.",
                " Selecting this option will allow you to submit a payment of ",
                " and your next payment date will be on ",
                "."
            ],
            option2: [
                "Your last payment was on ",
                " which is your billing cycle.",
                " Selecting this option will allow you to submit a payment of ",
                " and your next payment date will be on ",
                ".",
                " Your new billing cycle  will be the ",
                " of each month."
            ]
        },
        cardNumber: "Card Number*",
        firstname: "First Name*",
        lastname: "Last Name*",
        country: "Country*",
        city: "City*",
        state: "State*",
        zipcode: "Zip*",
        address: "Address*",
        phone: "Phone",
        email: "Email*",
        month: "Month*",
        year: "Year*",
        cvc: "CVC*",
        authorizeDesc:
            "I hereby authorize Franchise Record Pool to automatically bill my credit card in the amount of $19.99 every month until I decided to cancel my membership",
        termsofuseDesc:
            "By submitting your info, you agree to Franchise Record Pool's Terms of Use. You also confirm that the information you submit on this form is correct and solely yours.",
        statusLabel: "Status",
        cancelSubscriptionText:
            "You are about to cancel your subscription. Please be advised that all cancellations are processed within 24-48 hrs regardless of date of last payment. It is the members responsibility to cancel within a reasonable time of payment as no refunds will be processed. *IMPORTANT* If you do not receive a confirmation of your cancellation within 48 hrs contact us immediately to ensure your cancellation request was received. You can also call 347-449-5129 to verify or request cancellation. Thank you.",
        methodLabel: "Payment Method",
        choosePayment: "Select payment method",
        processingPaypalSubscription: "Process PayPal Subscription",
        cancelAgreementLink: "Terms and conditions",
        cancelSubscriptionHead: "Cancel Subscription?",
        statuses: {
            active: "Active",
            inactive: "Inactive",
            expired: "Expires on "
        },
        methods: {
            type: {
                creditCard: "Credit Card"
            }
        },
        statusesExplonation: {
            inactive: "You have no active subscribtion",
            active: ["Next payment", "occours on"],
            canceled: "The subscription has been canceled"
        },
        buttons: {
            subscribe: "Get subscription",
            cancel: "Cancel subscription",
            processing: "Processing...",
            setCard: "Subscribe",
            updateCard: "Change Card",
            change: "Change",
            payWithPaypal: "PayPal",
            payWithCard: "Credit Card",
            revive: "Submit Payment Now"
        },
        setCreditCardHeader: "New credit card",
        paypalHoldError:
            "We are not able to process your payment at this time. Paypal has put your payment status on hold. Please contact Paypal to confirm why your payment is on hold. We can not activate your account until a successful payment is made.",
        history: {
            title: "Billing History",
            date: "Date",
            cost: "Cost",
            card: "Card"
        }
    }
};

export default subscription;
