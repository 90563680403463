import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";

import t from "helpers/getText";
import { Button } from "components/common/ControlElems";
import routerDecorator from "components/helpers/withRouter";

import {
    CancelSubscriptionDialog,
    SetCreditCardDialog,
    ChoosePayMethodDialog,
    ProcessPaypalSubscriptionDialog,
    // UnpaidDialog
} from "./Dialogs";

import styles from "./Subscription.module.scss";
import { AuthContext } from "context/AuthContext";
import { SubscriptionContext } from "context/SubscriptionContext";
import moment from "moment";

Subscription.propTypes = {
    match: PropTypes.object
};

const DIALOGS = {
    choosePayMethod: "choosePayMethod",
    cancelSubscription: "cancelSubscription",
    setCreditCard: "setCreditCard",
    processPaypalSubscription: "processPaypalSubscription",
    unpaid: "unpaid"
};

function Subscription({ match: { params } }) {
    const { user } = useContext(AuthContext);
    const {
        getPrice,
        // price,
        getHistory,
        // history,
        subscriptionUserProcessing
    } = useContext(SubscriptionContext);
    useEffect(() => {
        getPrice();
        getHistory();
    }, []);
    const [activeDialog, setActiveDialog] = useState(
        getInitialDialogName(params)
    );
    const [currentOption, setCurrentOption] = useState(0);
    const subscription = _.get(user, "subscription", {});
    const isActive = _.get(user, "subscription.active", false);
    const {
        // months,
        newStartDate,
        summToPay,
        status: reviveStatus,
        previousExpiredDate,
        newStartDateOption1,
        summToPayOption1,
        newStartDateOption2,
        summToPayOption2
    } = _.get(user, "reviveSubscriptionInfo", {});
    // const status = isActive ? "active" : "inactive";
    const isCanceled = isActive && subscription.canceled;
    // const expiredDate = _.get(subscription, "expiredDate", null);
    return (
        <section
            className={classnames(
                styles.container,
                isCanceled && styles.canceled
            )}
        >
            {/* <span className={styles.statusLabel}>
                {t("subscription.statusLabel")}
            </span>
            <h3 className={styles.status}>
                {getStatus(status, isCanceled, expiredDate)}
            </h3>
            <div className={styles.explonation}>
                {getSubtext({ expiredDate, isCanceled, price, subscription })}
            </div> */}
            {summToPay && reviveStatus !== "REVIVE_CREATE_NEW_SUBSCRIPTION" && (
                <div className={styles.reviveInfo}>
                    <span>{t("subscription.revive.pay[0]")}</span>
                    <span>{t("subscription.revive.pay[1]")}</span>
                    <span className={styles.important}>${summToPay}.</span>
                    <span>{t("subscription.revive.pay[2]")}</span>
                    <span className={styles.important}>
                        {moment(newStartDate).format("MMM Do YYYY")}
                    </span>
                    <span>{t("subscription.revive.pay[3]")}</span>
                    <span className={styles.important}>
                        {moment(newStartDate).format("Do")}
                    </span>
                    <span>{t("subscription.revive.pay[4]")}</span>
                </div>
            )}
            {reviveStatus === "REVIVE_SPECIFIC_CASE" && (
                <div className={styles["specific-case"]}>
                    <div className={styles.reviveInfo}>
                        <div className={styles["specific-case-option-wrapper"]}>
                            <span>{t("subscription.specific.option1[0]")}</span>
                            <span className={styles.important}>
                                {moment(previousExpiredDate).format(
                                    "Do MMM YYYY"
                                )}
                            </span>
                            <span>{t("subscription.specific.option1[1]")}</span>
                            <span>{t("subscription.specific.option1[2]")}</span>
                            <span className={styles.important}>
                                ${summToPayOption1}
                            </span>
                            <span>{t("subscription.specific.option1[3]")}</span>
                            <span className={styles.important}>
                                {moment(newStartDateOption1).format(
                                    "Do MMM YYYY"
                                )}
                            </span>
                            <span>{t("subscription.specific.option1[4]")}</span>
                        </div>
                        <div className={styles["specific-case-button-wrapper"]}>
                            <Button
                                color="bright"
                                className={styles.registerButton}
                                label={t("subscription.buttons.subscribe")}
                                onClick={() => {
                                    setCurrentOption(1);
                                    setActiveDialog(DIALOGS.choosePayMethod);
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.reviveInfo}>
                        <div className={styles["specific-case-option-wrapper"]}>
                            <span>{t("subscription.specific.option2[0]")}</span>
                            <span className={styles.important}>
                                {moment(previousExpiredDate).format(
                                    "Do MMM YYYY"
                                )}
                            </span>
                            <span>{t("subscription.specific.option2[1]")}</span>
                            <span>{t("subscription.specific.option2[2]")}</span>
                            <span className={styles.important}>
                                ${summToPayOption2}
                            </span>
                            <span>{t("subscription.specific.option2[3]")}</span>
                            <span className={styles.important}>
                                {moment(newStartDateOption2).format(
                                    "Do MMM YYYY"
                                )}
                            </span>
                            <span>{t("subscription.specific.option2[4]")}</span>
                            <span>{t("subscription.specific.option2[5]")}</span>
                            <span className={styles.important}>
                                {moment(newStartDateOption2).format("Do")}
                            </span>
                            <span>{t("subscription.specific.option2[6]")}</span>
                        </div>
                        <div className={styles["specific-case-button-wrapper"]}>
                            <Button
                                color="bright"
                                className={styles.registerButton}
                                label={t("subscription.buttons.subscribe")}
                                onClick={() => {
                                    setCurrentOption(2);
                                    setActiveDialog(DIALOGS.choosePayMethod);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {/* {Boolean(subscription) === false &&
                reviveStatus !== "REVIVE_SPECIFIC_CASE" && (
                    <Button
                        color="bright"
                        className={styles.registerButton}
                        label={t("subscription.buttons.subscribe")}
                        onClick={() => setActiveDialog(DIALOGS.choosePayMethod)}
                    />
                )} */}
            {reviveStatus === "REVIVE_CREATE_NEW_SUBSCRIPTION" && (
                <Button
                    color="bright"
                    className={styles.registerButton}
                    label={t(
                        `subscription.buttons.${
                            subscriptionUserProcessing
                                ? "processing"
                                : "subscribe"
                        }`
                    )}
                    onClick={() => setActiveDialog(DIALOGS.choosePayMethod)}
                    disabled={subscriptionUserProcessing}
                />
            )}

            {isActive && !isCanceled && (
                <Button
                    className={styles.registerButton}
                    label={t("subscription.buttons.cancel")}
                    onClick={() => setActiveDialog(DIALOGS.cancelSubscription)}
                />
            )}
            {isCanceled &&
                reviveStatus !== "REVIVE_SPECIFIC_CASE" &&
                reviveStatus !== "REVIVE_TERMINATED_SUBSCRIPTION" && (
                    <Button
                        color="bright"
                        className={styles.registerButton}
                        label={t("subscription.buttons.revive")}
                        onClick={() => setActiveDialog(DIALOGS.choosePayMethod)}
                    />
                )}
            {!isCanceled &&
                reviveStatus === "REVIVE_TERMINATED_SUBSCRIPTION" && (
                    <Button
                        color="bright"
                        className={styles.registerButton}
                        label={t("subscription.buttons.revive")}
                        onClick={() => setActiveDialog(DIALOGS.choosePayMethod)}
                    />
                )}

            {/* {history.length > 0 && (
                <div className={styles.billingHistory}>
                    <h3 className={styles.header}>
                        {t("subscription.history.title")}
                    </h3>
                    <div className={styles.titles}>
                        <p className={styles.left}>
                            {t("subscription.history.date")}
                        </p>
                        <p className={styles.center}>
                            {t("subscription.history.cost")}
                        </p>
                        <p className={styles.right}>
                            {t("subscription.history.card")}
                        </p>
                    </div>
                    {history.map(charge => {
                        const dateComponents = new Date(charge.date)
                            .toString()
                            .split(" ");
                        return (
                            <div className={styles.row}>
                                <p className={styles.left}>{`${
                                    dateComponents[2]
                                } ${dateComponents[1]} ${
                                    dateComponents[3]
                                }`}</p>
                                <p className={styles.center}>
                                    ${charge.amount}
                                </p>
                                <p className={styles.right}>
                                    {charge.digits} {charge.cardExpire}
                                </p>
                            </div>
                        );
                    })}
                </div>
            )} */}

            {subscription &&
                isCanceled === false &&
                reviveStatus !== "REVIVE_CREATE_NEW_SUBSCRIPTION" && (
                    <div className={styles.paymentMethod}>
                        <span className={styles.methodLabel}>
                            {t("subscription.methodLabel")}
                        </span>
                        <div className={styles.paymentInfo}>
                            {/* <div>
                                <div>
                                    <span>
                                        {subscription.type !== "paypal"
                                            ? t(
                                                  "subscription.methods.type.creditCard"
                                              )
                                            : "Paypal"}
                                    </span>
                                    <span className={styles.digits}>
                                        {subscription.lastDigits}
                                    </span>
                                    <span>{subscription.cardExpire}</span>
                                </div>
                            </div> */}
                            <Button
                                className={styles.changeMethodButton}
                                isUpperCase={false}
                                label={t("subscription.buttons.change")}
                                onClick={() => {
                                    if (subscription.type === "authorize.net") {
                                        return setActiveDialog("setCreditCard");
                                    }

                                    setActiveDialog(DIALOGS.choosePayMethod);
                                }}
                                withoutBorder
                                withoutPadding
                            />
                        </div>
                    </div>
                )}
            {activeDialog === DIALOGS.processPaypalSubscription && (
                <ProcessPaypalSubscriptionDialog
                    onClose={() => setActiveDialog(null)}
                />
            )}
            {activeDialog === DIALOGS.choosePayMethod && (
                <ChoosePayMethodDialog
                    selectedOption={currentOption}
                    onChooseCard={() => setActiveDialog(DIALOGS.setCreditCard)}
                    onClose={() => setActiveDialog(null)}
                />
            )}
            {activeDialog === DIALOGS.setCreditCard && (
                <SetCreditCardDialog
                    isUpdateDialog={Boolean(
                        reviveStatus !== "REVIVE_CREATE_NEW_SUBSCRIPTION" &&
                            subscription &&
                            subscription.canceled === false
                    )}
                    selectedOption={currentOption}
                    onClose={() => setActiveDialog(null)}
                />
            )}
            {activeDialog === DIALOGS.cancelSubscription && (
                <CancelSubscriptionDialog
                    onClose={() => setActiveDialog(null)}
                />
            )}

            {/* {activeDialog === DIALOGS.unpaid && !subscription && (
                <UnpaidDialog onClose={() => setActiveDialog(null)} />
            )} */}
        </section>
    );
}

// function getStatus(status, isCanceled, expiredDate) {
//     let result = null;
//     if (isCanceled === false) {
//         result = t("subscription.statuses." + status);
//     } else {
//         result =
//             t("subscription.statuses.expired") +
//             moment(expiredDate).format("DD MMM YYYY");
//     }
//     return result;
// }

// function getSubtext({ expiredDate, isCanceled, subscription, price }) {
//     const status = subscription && subscription.active;
//     let result = t("subscription.statusesExplonation.inactive");
//     if (isCanceled) {
//         result = t("subscription.statusesExplonation.canceled");
//     } else if (subscription && status) {
//         result = [
//             <span key="1">
//                 {t("subscription.statusesExplonation.active[0]")}
//             </span>,
//             <span key="2" className={styles.brigth}>
//                 ${price}
//             </span>,
//             <span key="3">
//                 {t("subscription.statusesExplonation.active[1]")}
//             </span>,
//             <span key="4" className={styles.brigth}>
//                 {moment(expiredDate).format("DD MMM YYYY")}
//             </span>
//         ];
//     }
//     return result;
// }

function getInitialDialogName(params) {
    let result = null;
    if (params && params.type) {
        if (params.type === "processagreement") {
            result = DIALOGS.processPaypalSubscription;
        }

        if (params.type === "unpaid") {
            result = DIALOGS.unpaid;
        }
    }
    return result;
}

export default routerDecorator(Subscription);
